var render = function () {
  var _vm$getThemeLoadData$, _vm$getThemeLoadData, _vm$getThemeLoadData$2, _vm$fontPopup$step1$n, _vm$fontPopup$step1$n2, _vm$fontPopup$step1$n3, _vm$fontPopup$step1$n4, _vm$fontPopup$step1$n5, _vm$fontPopup$step1$n6;

  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('h1', {
    staticClass: "blind-a11y"
  }, [_vm._v("테마 에디터")]), _c('header', {
    staticClass: "DAV-topBar"
  }, [_c('button', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.$route.query.mode !== 'partner',
      expression: "$route.query.mode !== 'partner'"
    }],
    staticClass: "DAV-topBar--exit",
    on: {
      "click": _vm.exitTheme
    }
  }, [_vm._v(" 테마 나가기 ")]), _c('h2', {
    staticClass: "DAV-topBar__theme-name"
  }, [_vm._v(_vm._s((_vm$getThemeLoadData$ = _vm.getThemeLoadData.theme) === null || _vm$getThemeLoadData$ === void 0 ? void 0 : _vm$getThemeLoadData$.name))]), _vm.$route.path !== '/option' ? _c('div', {
    staticClass: "DAV-topBar__device"
  }, [_c('v-dropdown', {
    attrs: {
      "theme": "black-dropdown",
      "shown": _vm.tooltipState,
      "autoHide": false,
      "placement": "top",
      "triggers": []
    },
    scopedSlots: _vm._u([{
      key: "popper",
      fn: function fn() {
        return [_c('div', {
          domProps: {
            "innerHTML": _vm._s(_vm.tooltipMsg)
          }
        }), _c('div', {
          staticClass: "d-flex df-row DAV-topBar__device--tooltip"
        }, [_c('div', {
          staticClass: "spacer"
        }), _c('div', {
          staticClass: "cursor d-flex df-row centered",
          on: {
            "click": function click($event) {
              return _vm.neverShow('init-value-tooltip');
            }
          }
        }, [_c('span', {
          staticClass: "DAV-topBar__device--tooltip-text"
        }, [_vm._v(" 다시 열지 않기 ")]), _c('img', {
          attrs: {
            "src": require('@/assets/images/common/btn_close_popup.svg')
          }
        })])])];
      },
      proxy: true
    }], null, false, 251006324)
  }, [_c('button', {
    staticClass: "DAV-topBar__device-pc ga_e_1",
    class: {
      'DAV-topBar__device--active': _vm.getDeviceType === 'desktop'
    },
    on: {
      "click": function click($event) {
        return _vm.setDeviceType('desktop');
      }
    }
  }, [_vm._v(" pc ")]), _c('button', {
    staticClass: "DAV-topBar__device-mobile ga_e_2",
    class: {
      'DAV-topBar__device--active': _vm.getDeviceType === 'mobile'
    },
    on: {
      "click": function click($event) {
        return _vm.setDeviceType('mobile');
      }
    }
  }, [_vm._v(" mobile ")])])], 1) : _vm._e(), _vm.$route.path !== '/option' ? _c('button', {
    staticClass: "DAV-topBar__theme-save",
    class: {
      'DAV-topBar__theme-save--completed': _vm.getAttach
    },
    on: {
      "click": _vm.themeSave
    }
  }, [_vm._v(" 저장 ")]) : _vm._e(), _vm.$route.path !== '/option' ? _c('button', {
    staticClass: "DAV-topBar__more",
    on: {
      "click": function click($event) {
        $event.stopPropagation();
        return _vm.toggleLayer.apply(null, arguments);
      }
    }
  }, [_vm._v(" 더보기 ")]) : _vm._e(), _vm.$route.path === '/option' ? _c('button', {
    staticClass: "DAV-topBar--close",
    on: {
      "click": function click($event) {
        $event.stopPropagation();
        return _vm.closeOption.apply(null, arguments);
      }
    }
  }, [_vm._v(" 닫기 ")]) : _vm._e(), _c('div', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.isHeaderLayer,
      expression: "isHeaderLayer"
    }, {
      name: "click-outside",
      rawName: "v-click-outside:toggleLayer",
      arg: "toggleLayer"
    }],
    staticClass: "DAV-topBar__layer"
  }, [_c('button', {
    staticClass: "DAV-topBar__layer--close",
    on: {
      "click": function click($event) {
        $event.stopPropagation();
        return _vm.toggleLayer.apply(null, arguments);
      }
    }
  }, [_vm._v(" 닫기 ")]), _c('ul', {
    staticClass: "DAV-topBar__links"
  }, [_c('li', {
    staticClass: "DAV-topBar__links--preview"
  }, [_c('button', {
    staticClass: "ga_e_3",
    attrs: {
      "type": "button"
    },
    on: {
      "click": _vm.previewTheme
    }
  }, [_vm._v(" 미리보기 ")])]), _c('li', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.$route.query.mode !== 'partner',
      expression: "$route.query.mode !== 'partner'"
    }],
    staticClass: "DAV-topBar__links--export",
    class: {
      'DAV-topBar__links--disabled': ((_vm$getThemeLoadData = _vm.getThemeLoadData) === null || _vm$getThemeLoadData === void 0 ? void 0 : (_vm$getThemeLoadData$2 = _vm$getThemeLoadData.theme) === null || _vm$getThemeLoadData$2 === void 0 ? void 0 : _vm$getThemeLoadData$2.userSolutionId) === null
    }
  }, [_c('button', {
    staticClass: "ga_e_4",
    attrs: {
      "type": "button"
    },
    on: {
      "click": _vm.exportTheme
    }
  }, [_vm._v(" 테마 내보내기 ")])]), _c('li', {
    staticClass: "DAV-topBar__links--style"
  }, [_c('button', {
    staticClass: "ga_e_5",
    attrs: {
      "type": "button"
    },
    on: {
      "click": _vm.openFontPopup
    }
  }, [_vm._v(" 테마 서체설정 ")])]), _c('li', {
    staticClass: "DAV-topBar__links--revert"
  }, [_c('button', {
    staticClass: "ga_e_6",
    attrs: {
      "type": "button"
    },
    on: {
      "click": _vm.revertTheme
    }
  }, [_vm._v(" 테마 되돌리기 ")])])])])]), _c('dualball-spinner', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.isLoading,
      expression: "isLoading"
    }]
  }), _vm.fontPopup.step === 1 ? _c('popup', {
    staticClass: "font-popup",
    on: {
      "popupClose": _vm.closeFontPopup,
      "popupSave": _vm.whichSaveFont
    },
    scopedSlots: _vm._u([{
      key: "title",
      fn: function fn() {
        return [_c('div', [_vm._v("테마 서체 설정")])];
      },
      proxy: true
    }, {
      key: "footer",
      fn: function fn() {
        return [_vm._v(" 설정 저장하기"), _c('span', {
          staticStyle: {
            "margin-left": "10px"
          }
        }, [_vm._v(">")])];
      },
      proxy: true
    }], null, false, 146524482)
  }, [_c('div', {
    staticClass: "font-popup-content1"
  }, [_c('div', {
    staticClass: "font-title-wrap"
  }, [_c('div', {
    staticClass: "font-title"
  }, [_vm._v(" 대표 서체"), _c('span', [_vm._v(" (필수)")]), _c('tooltip', {
    attrs: {
      "text": "\uFF65 \uD14C\uB9C8\uC758 \uC804\uBC18\uC801\uC778 \uAE30\uBCF8 \uC11C\uCCB4 \uC2A4\uD0C0\uC77C\uC744 \uC124\uC815\uD560 \uC218 \uC788\uC2B5\uB2C8\uB2E4.<br/>\n            \uFF65 \uD14C\uB9C8 \uD3B8\uC9D1 \uC2DC \uCEF4\uD3EC\uB10C\uD2B8 \uC635\uC158\uC5D0\uC11C \uC11C\uCCB4\uB97C \uC120\uD0DD\uD558\uC2DC\uBA74 \uD14C\uB9C8 \uC11C\uCCB4 \uC2A4\uD0C0\uC77C\uC744 \uC77C\uAD04 \uC801\uC6A9\uD560 \uC218 \uC788\uC2B5\uB2C8\uB2E4.<br/>\n            \uFF65 \uC77C\uBD80 \uAC00\uB3C5\uC131\uC744 \uC800\uD558\uC2DC\uD0AC \uC218 \uC788\uB294 \uD14D\uC2A4\uD2B8\uB294 \uC124\uC815\uB41C \uD3F0\uD2B8\uB85C \uC801\uC6A9\uB418\uC9C0 \uC54A\uC744 \uC218 \uC788\uC2B5\uB2C8\uB2E4."
    }
  })], 1)]), _c('div', {
    staticClass: "font-select-wrap"
  }, [_c('div', {
    staticClass: "font-select",
    on: {
      "click": function click($event) {
        return _vm.moveFontList('no1', _vm.fontPopup.step1.no1.id);
      }
    }
  }, [_c('p', {
    staticClass: "font-name"
  }, [_vm._v(_vm._s((_vm$fontPopup$step1$n = _vm.fontPopup.step1.no1) === null || _vm$fontPopup$step1$n === void 0 ? void 0 : _vm$fontPopup$step1$n.name))]), _c('img', {
    staticClass: "font-example",
    attrs: {
      "src": (_vm$fontPopup$step1$n2 = _vm.fontPopup.step1.no1) === null || _vm$fontPopup$step1$n2 === void 0 ? void 0 : _vm$fontPopup$step1$n2.previewImage.url
    }
  }), _c('div', {
    staticClass: "botton-wrap"
  }, [_c('img', {
    attrs: {
      "src": require("@/assets/images/common/ico_more2.svg")
    }
  })])])]), _c('div', {
    staticClass: "font-title-wrap"
  }, [_c('div', {
    staticClass: "font-title"
  }, [_vm._v(" 보조 서체 1 "), _c('tooltip', {
    attrs: {
      "text": "\uFF65 \uD14C\uB9C8 \uD3B8\uC9D1 \uC2DC \uCEF4\uD3EC\uB10C\uD2B8 \uC635\uC158\uC5D0\uC11C \uC11C\uCCB4\uB97C \uC120\uD0DD\uD558\uC2DC\uBA74 \uD14C\uB9C8 \uC11C\uCCB4 \uC2A4\uD0C0\uC77C\uC744 \uC77C\uAD04 \uC801\uC6A9\uD560 \uC218 \uC788\uC2B5\uB2C8\uB2E4.<br/>\n            \uFF65 \uBCF4\uC870\uC11C\uCCB4 \uC0AD\uC81C \uC2DC \uD574\uB2F9 \uC11C\uCCB4\uAC00 \uC0AC\uC6A9\uB41C \uCEF4\uD3EC\uB10C\uD2B8\uB294 \uBAA8\uB450 \uB300\uD45C\uC11C\uCCB4\uB85C \uBCC0\uACBD\uB418\uC624\uB2C8 \uC0AD\uC81C \uC2DC \uC720\uC758\uD574\uC8FC\uC2DC\uAE30 \uBC14\uB78D\uB2C8\uB2E4.<br/>\n            \uFF65 \uC77C\uBD80 \uAC00\uB3C5\uC131\uC744 \uC800\uD558\uC2DC\uD0AC \uC218 \uC788\uB294 \uD14D\uC2A4\uD2B8\uB294 \uC124\uC815\uB41C \uD3F0\uD2B8\uB85C \uC801\uC6A9\uB418\uC9C0 \uC54A\uC744 \uC218 \uC788\uC2B5\uB2C8\uB2E4."
    }
  })], 1)]), _c('div', {
    staticClass: "font-select-wrap"
  }, [_vm.fontPopup.step1.no2 ? _c('div', {
    staticClass: "font-select",
    on: {
      "click": function click($event) {
        return _vm.moveFontList('no2', _vm.fontPopup.step1.no2.id);
      }
    }
  }, [_c('p', {
    staticClass: "font-name"
  }, [_vm._v(_vm._s((_vm$fontPopup$step1$n3 = _vm.fontPopup.step1.no2) === null || _vm$fontPopup$step1$n3 === void 0 ? void 0 : _vm$fontPopup$step1$n3.name))]), _c('img', {
    staticClass: "font-example",
    attrs: {
      "src": (_vm$fontPopup$step1$n4 = _vm.fontPopup.step1.no2) === null || _vm$fontPopup$step1$n4 === void 0 ? void 0 : _vm$fontPopup$step1$n4.previewImage.url
    }
  }), _c('div', {
    staticClass: "botton-wrap"
  }, [_c('img', {
    attrs: {
      "src": require("@/assets/images/common/ico_trash2.svg")
    },
    on: {
      "click": function click($event) {
        $event.stopPropagation();
        return _vm.deleteFont('no2');
      }
    }
  }), _c('div', {
    staticClass: "line"
  }), _c('img', {
    attrs: {
      "src": require("@/assets/images/common/ico_more2.svg")
    }
  })])]) : _c('div', {
    staticClass: "font-select-empty",
    on: {
      "click": function click($event) {
        return _vm.moveFontList('no2', null);
      }
    }
  }, [_c('div', {
    staticClass: "plus-button"
  }, [_vm._v("+")]), _vm._v(" 폰트 설정하기 ")])]), _c('div', {
    staticClass: "font-title-wrap"
  }, [_c('div', {
    staticClass: "font-title"
  }, [_vm._v(" 보조 서체 2 "), _c('tooltip', {
    attrs: {
      "text": "\uFF65 \uD14C\uB9C8 \uD3B8\uC9D1 \uC2DC \uCEF4\uD3EC\uB10C\uD2B8 \uC635\uC158\uC5D0\uC11C \uC11C\uCCB4\uB97C \uC120\uD0DD\uD558\uC2DC\uBA74 \uD14C\uB9C8 \uC11C\uCCB4 \uC2A4\uD0C0\uC77C\uC744 \uC77C\uAD04 \uC801\uC6A9\uD560 \uC218 \uC788\uC2B5\uB2C8\uB2E4.<br/>\n            \uFF65 \uBCF4\uC870\uC11C\uCCB4 \uC0AD\uC81C \uC2DC \uD574\uB2F9 \uC11C\uCCB4\uAC00 \uC0AC\uC6A9\uB41C \uCEF4\uD3EC\uB10C\uD2B8\uB294 \uBAA8\uB450 \uB300\uD45C\uC11C\uCCB4\uB85C \uBCC0\uACBD\uB418\uC624\uB2C8 \uC0AD\uC81C \uC2DC \uC720\uC758\uD574\uC8FC\uC2DC\uAE30 \uBC14\uB78D\uB2C8\uB2E4.<br/>\n            \uFF65 \uC77C\uBD80 \uAC00\uB3C5\uC131\uC744 \uC800\uD558\uC2DC\uD0AC \uC218 \uC788\uB294 \uD14D\uC2A4\uD2B8\uB294 \uC124\uC815\uB41C \uD3F0\uD2B8\uB85C \uC801\uC6A9\uB418\uC9C0 \uC54A\uC744 \uC218 \uC788\uC2B5\uB2C8\uB2E4."
    }
  })], 1)]), _c('div', {
    staticClass: "font-select-wrap"
  }, [_vm.fontPopup.step1.no3 ? _c('div', {
    staticClass: "font-select",
    on: {
      "click": function click($event) {
        return _vm.moveFontList('no3', _vm.fontPopup.step1.no3.id);
      }
    }
  }, [_c('p', {
    staticClass: "font-name"
  }, [_vm._v(_vm._s((_vm$fontPopup$step1$n5 = _vm.fontPopup.step1.no3) === null || _vm$fontPopup$step1$n5 === void 0 ? void 0 : _vm$fontPopup$step1$n5.name))]), _c('img', {
    staticClass: "font-example",
    attrs: {
      "src": (_vm$fontPopup$step1$n6 = _vm.fontPopup.step1.no3) === null || _vm$fontPopup$step1$n6 === void 0 ? void 0 : _vm$fontPopup$step1$n6.previewImage.url
    }
  }), _c('div', {
    staticClass: "botton-wrap"
  }, [_c('img', {
    attrs: {
      "src": require("@/assets/images/common/ico_trash2.svg")
    },
    on: {
      "click": function click($event) {
        $event.stopPropagation();
        return _vm.deleteFont('no3');
      }
    }
  }), _c('div', {
    staticClass: "line"
  }), _c('img', {
    attrs: {
      "src": require("@/assets/images/common/ico_more2.svg")
    }
  })])]) : _c('div', {
    staticClass: "font-select-empty",
    on: {
      "click": function click($event) {
        return _vm.moveFontList('no3', null);
      }
    }
  }, [_c('div', {
    staticClass: "plus-button"
  }, [_vm._v("+")]), _vm._v(" 폰트 설정하기 ")])])])]) : _vm._e(), _vm.fontPopup.step === 2 ? _c('popup', {
    staticClass: "font-popup",
    on: {
      "popupClose": _vm.closeFontPopup
    },
    scopedSlots: _vm._u([{
      key: "title",
      fn: function fn() {
        return [_c('div', [_c('img', {
          staticClass: "prev-button",
          attrs: {
            "src": require("@/assets/images/common/bul_arr_left_gray.svg")
          },
          on: {
            "click": function click($event) {
              _vm.fontPopup.step = 1;
            }
          }
        }), _vm._v("서체 설정 ")])];
      },
      proxy: true
    }], null, false, 2339538486)
  }, [_c('div', {
    staticClass: "font-popup-content2"
  }, [_c('div', {
    staticClass: "font-select-wrap"
  }, _vm._l(_vm.fontPopup.step2.list, function (font) {
    return _c('div', {
      key: font.id,
      staticClass: "font-select",
      class: {
        'font-select-active': font.id === _vm.fontPopup.step2.id,
        'font-select-disabled': _vm.fontPopup.step2.selectIds.includes(font.id)
      },
      on: {
        "click": function click($event) {
          _vm.fontPopup.step2.selectIds.includes(font.id) ? '' : _vm.selectFont(font);
        }
      }
    }, [_c('p', {
      staticClass: "font-name"
    }, [_vm._v(_vm._s(font.name))]), _c('img', {
      staticClass: "font-example",
      attrs: {
        "src": font.previewImage.url
      }
    })]);
  }), 0)])]) : _vm._e(), _vm.isLoadingSpinner ? _c('spinner') : _vm._e()], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }