<template>
  <div>
    <h1 class="blind-a11y">테마 에디터</h1>
    <header class="DAV-topBar">
      <button
        v-show="$route.query.mode !== 'partner'"
        class="DAV-topBar--exit"
        @click="exitTheme"
      >
        테마 나가기
      </button>
      <h2 class="DAV-topBar__theme-name">{{ getThemeLoadData.theme?.name }}</h2>
      <div v-if="$route.path !== '/option'" class="DAV-topBar__device">
        <v-dropdown
          theme="black-dropdown"
          :shown="tooltipState"
          :autoHide="false"
          placement="top"
          :triggers="[]"
        >
          <button
            class="DAV-topBar__device-pc ga_e_1"
            :class="{'DAV-topBar__device--active': getDeviceType === 'desktop'}"
            @click="setDeviceType('desktop')"
          >
            pc
          </button>
          <button
            class="DAV-topBar__device-mobile ga_e_2"
            :class="{'DAV-topBar__device--active': getDeviceType === 'mobile'}"
            @click="setDeviceType('mobile')"
          >
            mobile
          </button>
          <template #popper>
            <div v-html="tooltipMsg"></div>
            <div class="d-flex df-row DAV-topBar__device--tooltip">
              <div class="spacer"></div>
              <div
                class="cursor d-flex df-row centered"
                @click="neverShow('init-value-tooltip')"
              >
                <span class="DAV-topBar__device--tooltip-text">
                  다시 열지 않기
                </span>
                <img
                  :src="require('@/assets/images/common/btn_close_popup.svg')"
                />
              </div>
            </div>
          </template>
        </v-dropdown>
      </div>
      <button
        v-if="$route.path !== '/option'"
        class="DAV-topBar__theme-save"
        :class="{'DAV-topBar__theme-save--completed': getAttach}"
        @click="themeSave"
      >
        저장
      </button>
      <button
        v-if="$route.path !== '/option'"
        class="DAV-topBar__more"
        @click.stop="toggleLayer"
      >
        더보기
      </button>
      <button
        v-if="$route.path === '/option'"
        class="DAV-topBar--close"
        @click.stop="closeOption"
      >
        닫기
      </button>
      <div
        class="DAV-topBar__layer"
        v-show="isHeaderLayer"
        v-click-outside:toggleLayer
      >
        <button class="DAV-topBar__layer--close" @click.stop="toggleLayer">
          닫기
        </button>
        <ul class="DAV-topBar__links">
          <li class="DAV-topBar__links--preview">
            <button type="button" @click="previewTheme" class="ga_e_3">
              미리보기
            </button>
          </li>
          <li
            v-show="$route.query.mode !== 'partner'"
            class="DAV-topBar__links--export"
            :class="{
              'DAV-topBar__links--disabled':
                getThemeLoadData?.theme?.userSolutionId === null
            }"
          >
            <button type="button" @click="exportTheme" class="ga_e_4">
              테마 내보내기
            </button>
          </li>
          <li class="DAV-topBar__links--style">
            <button type="button" @click="openFontPopup" class="ga_e_5">
              테마 서체설정
            </button>
          </li>
          <li class="DAV-topBar__links--revert">
            <button type="button" @click="revertTheme" class="ga_e_6">
              테마 되돌리기
            </button>
          </li>
          <!-- 추후 오픈 -->
          <!--li class="DAV-topBar__links--setting">
            <button type="button">환경설정</button>
          </li-->
          <!-- //추후 오픈 -->
        </ul>
      </div>
    </header>
    <dualball-spinner v-show="isLoading" />
    <!-- 서체 설정 팝업 step1 -->
    <popup
      v-if="fontPopup.step === 1"
      @popupClose="closeFontPopup"
      @popupSave="whichSaveFont"
      class="font-popup"
    >
      <template v-slot:title>
        <div>테마 서체 설정</div>
      </template>
      <div class="font-popup-content1">
        <div class="font-title-wrap">
          <div class="font-title">
            대표 서체<span> (필수)</span>
            <tooltip
              :text="`･ 테마의 전반적인 기본 서체 스타일을 설정할 수 있습니다.<br/>
              ･ 테마 편집 시 컴포넌트 옵션에서 서체를 선택하시면 테마 서체 스타일을 일괄 적용할 수 있습니다.<br/>
              ･ 일부 가독성을 저하시킬 수 있는 텍스트는 설정된 폰트로 적용되지 않을 수 있습니다.`"
            />
          </div>
        </div>
        <div class="font-select-wrap">
          <div
            class="font-select"
            @click="moveFontList('no1', fontPopup.step1.no1.id)"
          >
            <p class="font-name">{{ fontPopup.step1.no1?.name }}</p>
            <img
              class="font-example"
              :src="fontPopup.step1.no1?.previewImage.url"
            />
            <div class="botton-wrap">
              <img src="@/assets/images/common/ico_more2.svg" />
            </div>
          </div>
        </div>
        <div class="font-title-wrap">
          <div class="font-title">
            보조 서체 1
            <tooltip
              :text="`･ 테마 편집 시 컴포넌트 옵션에서 서체를 선택하시면 테마 서체 스타일을 일괄 적용할 수 있습니다.<br/>
              ･ 보조서체 삭제 시 해당 서체가 사용된 컴포넌트는 모두 대표서체로 변경되오니 삭제 시 유의해주시기 바랍니다.<br/>
              ･ 일부 가독성을 저하시킬 수 있는 텍스트는 설정된 폰트로 적용되지 않을 수 있습니다.`"
            />
          </div>
        </div>
        <div class="font-select-wrap">
          <div
            v-if="fontPopup.step1.no2"
            class="font-select"
            @click="moveFontList('no2', fontPopup.step1.no2.id)"
          >
            <p class="font-name">{{ fontPopup.step1.no2?.name }}</p>
            <img
              class="font-example"
              :src="fontPopup.step1.no2?.previewImage.url"
            />
            <div class="botton-wrap">
              <img
                @click.stop="deleteFont('no2')"
                src="@/assets/images/common/ico_trash2.svg"
              />
              <div class="line"></div>
              <img src="@/assets/images/common/ico_more2.svg" />
            </div>
          </div>
          <div
            v-else
            class="font-select-empty"
            @click="moveFontList('no2', null)"
          >
            <div class="plus-button">+</div>
            폰트 설정하기
          </div>
        </div>
        <div class="font-title-wrap">
          <div class="font-title">
            보조 서체 2
            <tooltip
              :text="`･ 테마 편집 시 컴포넌트 옵션에서 서체를 선택하시면 테마 서체 스타일을 일괄 적용할 수 있습니다.<br/>
              ･ 보조서체 삭제 시 해당 서체가 사용된 컴포넌트는 모두 대표서체로 변경되오니 삭제 시 유의해주시기 바랍니다.<br/>
              ･ 일부 가독성을 저하시킬 수 있는 텍스트는 설정된 폰트로 적용되지 않을 수 있습니다.`"
            />
          </div>
        </div>
        <div class="font-select-wrap">
          <div
            v-if="fontPopup.step1.no3"
            class="font-select"
            @click="moveFontList('no3', fontPopup.step1.no3.id)"
          >
            <p class="font-name">{{ fontPopup.step1.no3?.name }}</p>
            <img
              class="font-example"
              :src="fontPopup.step1.no3?.previewImage.url"
            />
            <div class="botton-wrap">
              <img
                @click.stop="deleteFont('no3')"
                src="@/assets/images/common/ico_trash2.svg"
              />
              <div class="line"></div>
              <img src="@/assets/images/common/ico_more2.svg" />
            </div>
          </div>
          <div
            v-else
            class="font-select-empty"
            @click="moveFontList('no3', null)"
          >
            <div class="plus-button">+</div>
            폰트 설정하기
          </div>
        </div>
      </div>
      <template v-slot:footer>
        설정 저장하기<span style="margin-left: 10px">></span>
      </template>
    </popup>
    <!-- 서체 설정 팝업 step2 -->
    <popup
      v-if="fontPopup.step === 2"
      @popupClose="closeFontPopup"
      class="font-popup"
    >
      <template v-slot:title>
        <div>
          <img
            @click="fontPopup.step = 1"
            class="prev-button"
            src="@/assets/images/common/bul_arr_left_gray.svg"
          />서체 설정
        </div>
      </template>
      <div class="font-popup-content2">
        <div class="font-select-wrap">
          <div
            v-for="font in fontPopup.step2.list"
            :key="font.id"
            class="font-select"
            :class="{
              'font-select-active': font.id === fontPopup.step2.id,
              'font-select-disabled': fontPopup.step2.selectIds.includes(
                font.id
              )
            }"
            @click="
              fontPopup.step2.selectIds.includes(font.id)
                ? ''
                : selectFont(font)
            "
          >
            <p class="font-name">{{ font.name }}</p>
            <img class="font-example" :src="font.previewImage.url" />
          </div>
        </div>
      </div>
    </popup>
    <spinner v-if="isLoadingSpinner" />
  </div>
</template>

<script>
import {createNamespacedHelpers} from "vuex";
import commonUtils from "@/utils/common.js";

const ModuleAuth = createNamespacedHelpers("ModuleAuth");
const ModuleAlert = createNamespacedHelpers("ModuleAlert");
const ModuleConfirm = createNamespacedHelpers("ModuleConfirm");
const ModuleEditor = createNamespacedHelpers("ModuleEditor");
const ModuleEditorContainer = createNamespacedHelpers("ModuleEditorContainer");
const ModuleTheme = createNamespacedHelpers("ModuleTheme");
const ModuleIntroCommon = createNamespacedHelpers("ModuleIntroCommon");

export default {
  data() {
    return {
      isLoading: false,
      isLoadingSpinner: false,
      isHeaderLayer: false,
      solutionName: null,
      linkedComponentIndex: null,
      fontPopup: {
        step: null,
        step1: {
          no1: null,
          no2: null,
          no3: null
        },
        step2: {
          no: null,
          id: null,
          list: [],
          selectIds: []
        }
      },
      tooltipState: false,
      tooltipMsg: `<span style="color:#D4FC79">디바이스별</span> 옵션 기본값을 입력해 주세요.`
    };
  },
  watch: {
    // 테마 통합 불러오기
    getThemeLoadData(data) {
      for (let solution of this.getSolutionListData) {
        if (data.theme.solutionId === solution.id) {
          this.solutionName = solution.name;
        }
      }
      if (this.$route.params.preview) {
        this.setPopup({page: "ThemePreview"});
      }
    },
    // 폰트 팝업 open (from ComponentOption)
    getFontEvent(data) {
      if (data) {
        this.openFontPopup();
        this.setFontEvent(false);
      }
    }
  },
  computed: {
    ...ModuleAuth.mapGetters(["getLoggedData"]),
    ...ModuleEditor.mapGetters([
      "getAttach",
      "getThemeId",
      "getDeviceType",
      "getSolutionListData",
      "getUserThemeHistoryId",
      "getLinkedComponentId",
      "getFontEvent",
      "getFontListData",
      "getUserFontListData",
      "getPopup"
    ]),
    ...ModuleEditorContainer.mapGetters([
      "getComponentLayout",
      "getComponentContainer"
    ]),
    ...ModuleTheme.mapGetters([
      "getThemeLoadData",
      "getThemeExportData",
      "getSaveHistoryListData"
    ])
  },
  methods: {
    // 테마 나가기
    exitTheme() {
      this.$router.push({
        name: "Theme",
        params: {siteId: this.getThemeLoadData.theme.userSiteId}
      });
    },
    // 레이어 show/hide
    toggleLayer(value) {
      this.isHeaderLayer = !value ? value : !this.isHeaderLayer;
    },
    // (옵션설정) 팝업 종료
    closeOption() {
      this.$emit("close");
    },
    // 테마 되돌리기
    revertTheme() {
      this.toggleLayer(false);
      this.setPanel("SaveHistory");
    },
    // 테마 미리보기 confirm
    previewTheme() {
      let payload = {};
      payload.themeId = this.getThemeId;
      payload.perPage = 1;

      this.actSaveHistoryList(payload).then(response => {
        const list = response.data.list;
        if (!list.length) {
          this.setAlert({
            isVisible: true,
            message: `변경된 내용을 미리보기 하려면<br>먼저 저장을 해야 합니다.`
          });
          return;
        }

        if (this.getAttach) {
          this.setConfirm({
            isVisible: true,
            message: `변경된 내용을 미리보기 하려면<br>먼저 저장을 해야 합니다.`,
            messageBtn: `미리보기`,
            event: () => {
              this.setPopup({page: "ThemePreview"});
            }
          });
        } else {
          this.setPopup({page: "ThemePreview"});
        }
      });
    },
    // 테마 내보내기 confirm
    exportTheme() {
      if (this.getThemeLoadData.theme.userSolutionId === null) {
        return;
      }
      this.toggleLayer(false);

      if (this.getAttach) {
        this.setConfirm({
          isVisible: true,
          message: `변경된 내용을 내보내기 하려면 먼저 저장을 해야 합니다.`,
          messageBtn: `내보내기`,
          event: () => {
            this.isLoading = true;
            this.themeExport();
          }
        });
      } else {
        this.setConfirm({
          isVisible: true,
          message: `이 테마를 ${this.solutionName}에 내보내기 하시겠습니까?`,
          messageBtn: `내보내기`,
          event: () => {
            this.isLoading = true;
            this.themeExport();
          }
        });
      }
    },
    // 테마 내보내기
    themeExport() {
      let payload = {};
      payload.themeId = this.getThemeId;
      payload.userSolutionId = this.getThemeLoadData.theme.userSolutionId;

      this.actThemeExport(payload).then(response => {
        this.isLoading = false;

        switch (response.status) {
          case 200:
            if (response.data.res === 200) {
              this.$store.dispatch("ModuleUser/actUserLog", {
                eventName: "테마 내보내기",
                requestLocation: "큐픽 > 어드민 > 테마관리 > 편집",
                solutionName: this.solutionName,
                themeId: this.$route.params.themeId
              });
              this.setAlert({
                isVisible: true,
                message: "테마 내보내기가 완료되었습니다."
              });
            } else {
              this.setAlert({
                isVisible: true,
                message: `테마 내보내기가 실패하였습니다.<br>(실패 사유: ${response.data.msg})`
              });
            }
            break;
          default:
            alert(response.data.errors[0].userMsg);
            break;
        }
      });
    },
    // 테마 통합 저장하기
    themeSave() {
      if (!this.getAttach) return;

      if (this.getPopup.page === "ComponentImage") {
        this.setAlert({
          isVisible: true,
          message: "이미지 적용하기를 하신 후 다시 시도해주세요."
        });
        return;
      }

      for (let i in this.getThemeLoadData.editablePages[0].layouts) {
        for (let j of this.getThemeLoadData.linkedComponentGroups) {
          if (j.id === this.getThemeLoadData.editablePages[0].layouts[i]) {
            j.linkedComponents = [];
            let sort = 0;
            for (let k of this.getComponentLayout[i]) {
              let componentData = this.getComponentContainer.get(k);
              componentData.sort = ++sort;
              componentData.userLinkedComponentGroupId = j.id;
              if (k === this.getLinkedComponentId) {
                // LinkedComponentId가 있을 경우 index set
                const ids = [
                  ...this.getComponentLayout["header"],
                  ...this.getComponentLayout["content"],
                  ...this.getComponentLayout["footer"]
                ];
                const index = ids.indexOf(k);
                this.linkedComponentIndex = index + 1;
              }
              j.linkedComponents.push(componentData);
            }
          }
        }
      }

      let payload = {};
      payload.themeId = this.getThemeId;
      payload.body = this.getThemeLoadData;

      if (this.$route.query.mode === "partner") {
        payload.mode = "partner";
      } else {
        payload.mode = "user";
        payload.body.userThemeHistoryId = this.getUserThemeHistoryId;
      }

      this.isLoading = true;

      this.actThemeSave(payload).then(response => {
        this.isLoading = false;

        switch (response.status) {
          case 201:
            this.$store.dispatch("ModuleUser/actUserLog", {
              eventName: "테마 저장",
              requestLocation: "큐픽 > 어드민 > 테마관리 > 편집",
              solutionName: this.solutionName,
              themeId: this.$route.params.themeId
            });
            this.setAlert({
              isVisible: true,
              message: `저장이 완료되었습니다.`,
              event: "saveTheme"
            });
            break;

          case 401:
            this.setTemp(response.status);
            this.setAlert({
              isVisible: true,
              message: `세션이 만료되었습니다.<br>계속하려면 다시 로그인 하세요.`,
              event: "sessionExpiration"
            });
            break;

          default:
            alert(response.data.errors[0].userMsg);
            break;
        }
      });
    },
    // 폰트 팝업 open
    openFontPopup() {
      this.fontPopup.step = 1;
      this.isHeaderLayer = false;
      this.$route.query.mode === "partner"
        ? this.partnerThemeFontList()
        : this.userFontList();
    },
    // 폰트저장 분기처리
    whichSaveFont(type) {
      this.$route.query.mode === "partner"
        ? this.savePartnerFont(type)
        : this.saveFont(type);
    },
    // 파트너 테마 폰트 목록
    partnerThemeFontList() {
      let payload = {};
      payload["id"] = this.$route.params.themeId;
      this.isLoadingSpinner = true;

      this.actThemeTypeFaces(payload).then(response => {
        for (const i of response.data) {
          if (i.name === "no1") {
            this.fontPopup.step1.no1 = i.typeface;
          }
          if (i.name === "no2") {
            this.fontPopup.step1.no2 = i.typeface;
          }
          if (i.name === "no3") {
            this.fontPopup.step1.no3 = i.typeface;
          }
        }
        this.fontList();
      });
    },
    // 회원 폰트 목록
    userFontList() {
      let payload = {
        user_theme_id: this.$route.params.themeId
      };
      this.isLoadingSpinner = true;
      this.actUserFontList(payload).then(response => {
        for (const i of response.data) {
          if (i.name === "no1") {
            this.fontPopup.step1.no1 = i.typeface;
          }
          if (i.name === "no2") {
            this.fontPopup.step1.no2 = i.typeface;
          }
          if (i.name === "no3") {
            this.fontPopup.step1.no3 = i.typeface;
          }
        }
        this.fontList();
      });
    },
    // 폰트 목록
    fontList() {
      let payload = {};
      payload.sortBy = "+name";
      this.actFontList(payload).then(response => {
        this.fontPopup.step2.list = response.data.list.filter(i =>
          this.getLoggedData.manager
            ? i.visible
            : i.visible && !i.onlyForManager
        );
        this.isLoadingSpinner = false;
      });
    },
    // 폰트 팝업 리스트로 이동 (step1 > step2)
    moveFontList(no, id) {
      this.fontPopup.step = 2;
      this.fontPopup.step2.no = no;
      this.fontPopup.step2.id = id;
      this.fontPopup.step2.selectIds = [];
      if (no !== "no1" && this.fontPopup.step1.no1)
        this.fontPopup.step2.selectIds.push(this.fontPopup.step1.no1.id);
      if (no !== "no2" && this.fontPopup.step1.no2)
        this.fontPopup.step2.selectIds.push(this.fontPopup.step1.no2.id);
      if (no !== "no3" && this.fontPopup.step1.no3)
        this.fontPopup.step2.selectIds.push(this.fontPopup.step1.no3.id);

      // 저장된 폰트 있으면 스크롤 focus
      if (this.fontPopup.step2.id) {
        setTimeout(() => {
          const active = document.querySelector(`.font-select-active`);
          const offest = active.offsetTop - 76;

          document.querySelector(".pop-content").scrollTo({
            top: offest,
            left: 0,
            behavior: "smooth"
          });
        }, 100);
      }
    },
    // 폰트 팝업 폰트 선택 (step2 > step1)
    selectFont(font) {
      if (this.fontPopup.step2.no === "no1") {
        this.fontPopup.step1.no1 = font;
      } else if (this.fontPopup.step2.no === "no2") {
        this.fontPopup.step1.no2 = font;
      } else if (this.fontPopup.step2.no === "no3") {
        this.fontPopup.step1.no3 = font;
      }

      this.fontPopup.step = 1;

      document.querySelector(".pop-content").scrollTo({
        top: 0,
        left: 0
      });
    },
    // 파트너 폰트 저장
    savePartnerFont(type) {
      let payload = {
        id: this.$route.params.themeId,
        no1: this.fontPopup.step1.no1.id
      };
      if (this.fontPopup.step1.no2 !== null) {
        payload.no2 = this.fontPopup.step1.no2.id;
      }
      if (this.fontPopup.step1.no3 !== null) {
        payload.no3 = this.fontPopup.step1.no3.id;
      }
      if (type !== "delete") this.fontPopup.step = null;
      this.isLoadingSpinner = true;
      this.actThemeTypeFacesUpdate(payload).then(() => {
        this.themeFontLoad();
      });
    },
    // 폰트 저장
    saveFont(type) {
      let payload = {
        user_theme_id: this.$route.params.themeId,
        no1: this.fontPopup.step1.no1.id
      };
      if (this.fontPopup.step1.no2 !== null) {
        payload.no2 = this.fontPopup.step1.no2.id;
      }
      if (this.fontPopup.step1.no3 !== null) {
        payload.no3 = this.fontPopup.step1.no3.id;
      }
      if (type !== "delete") this.fontPopup.step = null;
      this.isLoadingSpinner = true;
      this.actUserFontUpdate(payload).then(() => {
        this.$store.dispatch("ModuleUser/actUserLog", {
          eventName: type === "delete" ? "테마 서체 삭제" : "테마 서체 설정",
          requestLocation: "큐픽 > 어드민 > 테마관리 > 편집 > 테마 서체 설정",
          solutionName: this.solutionName,
          themeId: this.$route.params.themeId,
          fontname1: this.fontPopup.step1.no1
            ? this.fontPopup.step1.no1.name
            : "-",
          fontname2: this.fontPopup.step1.no2
            ? this.fontPopup.step1.no2.name
            : "-",
          fontname3: this.fontPopup.step1.no3
            ? this.fontPopup.step1.no3.name
            : "-"
        });
        this.themeFontLoad();
      });
    },
    // 폰트 삭제 confirm
    deleteFont(no) {
      this.setConfirm({
        isVisible: true,
        message: `서체 삭제 시 해당 서체가 적용된 컴포넌트는 모두 대표 서체로 변경되어 복구할 수 없습니다.<br>서체를 삭제하시겠습니까?`,
        messageBtn: `삭제하기`,
        event: () => {
          if (no === "no2") {
            this.fontPopup.step1.no2 = null;
          }
          if (no === "no3") {
            this.fontPopup.step1.no3 = null;
          }
          this.whichSaveFont("delete");
        }
      });
    },
    // 테마 통합 불러오기
    themeFontLoad() {
      let payload = {};
      payload.themeId = this.$route.params.themeId;
      if (this.$route.query.mode === "partner") payload.mode = "partner";

      this.actThemeFontLoad(payload).then(response => {
        this.setEvent("typefaces");
        this.$store.state.ModuleTheme.themeLoadData.typefaces.css =
          response.data.typefaces.css;
        this.isLoadingSpinner = false;
      });
    },
    // 폰트 팝업 close
    closeFontPopup() {
      this.fontPopup = {
        step: null,
        step1: {
          no1: null,
          no2: null,
          no3: null
        },
        step2: {
          no: null,
          id: null,
          list: null
        }
      };
    },
    // 다시 보지 않기 툴팁 처리
    neverShow(target) {
      const today = new Date();
      const expiryDate = new Date(today.setDate(today.getDate() + 999));
      commonUtils.setCookie(target, today, expiryDate);
      this.tooltipState = false;
    },
    ...ModuleIntroCommon.mapMutations(["setTemp"]),
    ...ModuleAlert.mapMutations(["setAlert"]),
    ...ModuleConfirm.mapMutations(["setConfirm"]),
    ...ModuleEditor.mapMutations([
      "setPopup",
      "setAttach",
      "setPanel",
      "setDeviceType",
      "setUserThemeHistoryId",
      "setLinkedComponentId",
      "setFontEvent"
    ]),
    ...ModuleEditor.mapActions([
      "actFontList",
      "actUserFontList",
      "actUserFontUpdate",
      "actThemeFontLoad"
    ]),
    ...ModuleEditorContainer.mapMutations(["setEvent"]),
    ...ModuleTheme.mapActions([
      "actThemeSave",
      "actThemeExport",
      "actThemeTypeFaces",
      "actThemeTypeFacesUpdate",
      "actSaveHistoryList"
    ])
  },
  created() {
    // alert
    this.$EventBus.$on("alertCancel", event => {
      // 테마 저장하기 완료
      if (event === "saveTheme") {
        // LinkedComponentId가 있을 경우 다시 set
        if (this.linkedComponentIndex) {
          this.setLinkedComponentId(this.linkedComponentIndex);
          this.linkedComponentIndex = null;
        }

        this.setAttach(false);
        this.setEvent("refresh");
        this.$EventBus.$emit("optionLoad", "load");
        this.setUserThemeHistoryId(null);
      } else if (event === "sessionExpiration") {
        // 세션 만료시
        if (this.$route.query.mode === "partner") {
          const partner = process.env.VUE_APP_PARTNER_CENTER;
          this.$router.replace(`/accounts/login?callback=${partner}`);
        } else {
          this.$router.replace(`/accounts/login`);
        }
      }
    });
  },
  mounted() {
    //(파트너) 옵션설정 모드일때 툴팁 온오프처리
    let cookie = commonUtils.getCookie("init-value-tooltip");
    if (this.$route.path === "/option" && !cookie) {
      this.tooltipState = true;
    } else {
      this.tooltipState = false;
    }
  },
  components: {
    DualballSpinner: () => import("@/components/basis/dualball-spinner")
  }
};
</script>

<style lang="scss" scoped>
.block__spinner {
  z-index: 20;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(255, 255, 255, 0.4);

  i {
    color: #309574;
    font-size: 60px;
  }
}
.DAV-topBar {
  &--exit {
    position: absolute;
    top: 19px;
    left: 18px;
    width: 21px;
    height: 18px;
    background-image: url($path + "ico_exit.svg");
    background-repeat: no-repeat;
    background-position: 0 0;
    text-indent: -9999px;
  }
  &__theme-save {
    position: absolute;
    top: 16px;
    right: 56px;
    width: 24px;
    height: 24px;
    background-image: url($path + "ico_save.svg");
    background-repeat: no-repeat;
    background-position: 50% 50%;
    text-indent: -9999px;

    &--completed {
      background-image: url($path + "ico_save_on.svg");
    }
  }
  &__more {
    position: absolute;
    top: 16px;
    right: 16px;
    width: 24px;
    height: 24px;
    background-image: url($path + "ico_more.svg");
    background-repeat: no-repeat;
    background-position: 50% 50%;
    text-indent: -9999px;
  }
  &__layer {
    z-index: 26;
    position: absolute;
    top: 8px;
    right: 8px;
    width: 184px;
    padding: 45px 24px 25px;
    background-color: #ffffff;
    border: 1px solid #d8dae5;
    box-sizing: border-box;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.16);
    border-radius: 8px;

    &--close {
      position: absolute;
      top: 10px;
      right: 12px;
      width: 16px;
      height: 16px;
      background-image: url($path + "ico_modal_close.svg");
      background-repeat: no-repeat;
      background-position: 50% 50%;
      text-indent: -9999px;
    }
  }
  &__links {
    li {
      margin-top: 15px;
      padding-left: 24px;
      background-repeat: no-repeat;
      background-position: 0 60%;

      &:first-child {
        margin-top: 0;
      }
      button {
        color: #484948;
        font-weight: 500;
        font-size: 14px;
      }
    }
    &--preview {
      background-image: url($path + "bul_m_preview.svg");
    }
    &--export {
      background-image: url($path + "bul_m_export.svg");
    }
    &--style {
      background-image: url($path + "bul_m_style.svg");
    }
    &--upload {
      background-image: url($path + "bul_m_upload.svg");
    }
    &--revert {
      background-image: url($path + "bul_m_revert.svg");
    }
    &--setting {
      background-image: url($path + "bul_m_setting.svg");
    }
    &--disabled {
      opacity: 0.3;
    }
  }
}
.font-popup {
  .prev-button {
    margin-right: 14px;
    cursor: pointer;
  }

  .font-popup-content1 {
    padding-bottom: 64px;
  }

  .font-popup-content2 {
    .font-select {
      margin-bottom: 8px;

      &:last-child {
        margin-bottom: 0px;
      }
    }

    .font-select-active {
      border: 1px solid #54c7a2 !important;
    }

    .font-select-disabled {
      cursor: not-allowed !important;
      opacity: 0.4;
    }
  }

  .font-title-wrap {
    padding: 0 16px;
    background: #fff;

    .font-title {
      position: relative;
      color: #606060;
      font-weight: 500;
      border-bottom: 1px solid #f1f1f1;
      height: 51px;
      line-height: 51px;
      box-sizing: border-box;

      span {
        font-size: 12px;
        color: #d14343;
      }

      .tooltip {
        margin-left: 4px;
      }
    }
  }

  .font-select-wrap {
    padding: 16px;

    .font-select {
      cursor: pointer;
      position: relative;
      width: 100%;
      background: #ffffff;
      border: 1px solid #dadada;
      box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.16);
      border-radius: 8px;
      padding: 11px 16px;
      box-sizing: border-box;

      .font-name {
        font-size: 12px;
        color: #606060;
        margin-bottom: 3px;
      }

      .font-example {
        max-width: 400px;
        width: 100%;
      }

      .botton-wrap {
        display: flex;
        position: absolute;
        top: 50%;
        right: 25px;
        transform: translateY(-50%);

        .line {
          width: 1px;
          height: 24px;
          background: #dcdcdc;
          margin: 0 16px;
        }

        img {
          cursor: pointer;
        }
      }
    }

    .font-select-empty {
      width: 100%;
      height: 79px;
      border: 2px dashed #d8dae5;
      border-radius: 8px;
      box-sizing: border-box;
      color: #8d8d8d;
      font-size: 14px;
      font-weight: 500;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;

      .plus-button {
        color: #b7b7b7;
        width: 38px;
        height: 38px;
        border: 1px solid #b7b7b7;
        border-radius: 50%;
        text-align: center;
        line-height: 38px;
        font-size: 32px;
        font-weight: 300;
        margin-right: 8px;
      }
    }
  }
}
/* 반응형 - desktop */
@media screen and (min-width: 1024px) {
  .DAV-topBar {
    &__theme-save,
    &__more {
      top: 8px;
      width: 40px;
      height: 40px;
      border: 1px solid #cdcdcd;
      box-sizing: border-box;
      box-shadow: 0px 1px 0px rgba(0, 0, 0, 0.08);
      border-radius: 8px;
    }
    &__theme-save {
      right: 72px;
    }
  }
}
/* 반응형 - mobile */
@media screen and (max-width: 1023px) {
}
</style>
